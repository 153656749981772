// src/App.js
import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import ValuesSection from './components/ValuesSection';
import CoursesSection from './components/CoursesSection';
import LearningMaterialSection from './components/LearningMaterialSection';
import ContactSection from './components/ContactSection';
import WhatsAppChatButton from './components/WhatsappChatButton';
import Footer from './components/Footer';


function App() {
  



  return (
    <div className="App">
      <Navbar />
      <HeroSection />
      <ValuesSection />
      <CoursesSection />
      <LearningMaterialSection />
      <ContactSection />
      <WhatsAppChatButton />
      <Footer />
   
    </div>
  );
}

export default App;

// src/components/ValuesSection.js
import React from 'react';

const ValuesSection = () => {
  return (
    <section id="values" className="py-24 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-16 gradient-text">Our Core Values</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {/* Repeat for each value */}
          <div className="text-center p-8 rounded-xl bg-gray-50 card-hover shadow-lg">
            <div className="w-20 h-20 mx-auto mb-6 bg-green-100 rounded-full flex items-center justify-center">
              <i className="fas fa-certificate text-3xl text-green-600"></i>
            </div>
            <h3 className="text-2xl font-bold mb-4">Certified Teachers</h3>
            <p className="text-gray-600 leading-relaxed">Learn from qualified and experienced Quran teachers who are dedicated to your success.</p>
          </div>

          <div className="text-center p-8 rounded-xl bg-gray-50 card-hover shadow-lg">
            <div className="w-20 h-20 mx-auto mb-6 bg-green-100 rounded-full flex items-center justify-center">
              <i className="fas fa-clock text-3xl text-green-600"></i>
            </div>
            <h3 className="text-2xl font-bold mb-4">Flexible Timing</h3>
            <p className="text-gray-600 leading-relaxed">Choose your preferred learning schedule that fits perfectly with your daily routine.</p>
          </div>

          <div className="text-center p-8 rounded-xl bg-gray-50 card-hover shadow-lg">
            <div className="w-20 h-20 mx-auto mb-6 bg-green-100 rounded-full flex items-center justify-center">
              <i className="fas fa-users text-3xl text-green-600"></i>
            </div>
            <h3 className="text-2xl font-bold mb-4">Personalized Attention</h3>
            <p className="text-gray-600 leading-relaxed">One-on-one sessions ensuring focused learning and individual progress tracking</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ValuesSection;

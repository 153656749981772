// src/components/Navbar.js
import React, { useState } from 'react';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <nav className="bg-gradient-to-r from-green-900 to-green-700 text-white p-6 fixed w-full z-50">
      <div className="container mx-auto flex justify-between items-center">
        <h1 className="text-3xl font-bold">
          MyQuranSheikh<span className="text-green-300">.com</span>
        </h1>
        
        {/* Hamburger icon for mobile */}
        <button className="md:hidden" onClick={toggleMenu}>
          <i className="fas fa-bars text-2xl"></i>
        </button>

        {/* Menu */}
        <div
          className={`${
            menuOpen ? 'flex' : 'hidden'
          } md:flex flex-col md:flex-row md:space-x-8 absolute md:relative top-full left-0 w-full md:w-auto bg-green-900 md:bg-transparent p-6 md:p-0 md:items-center transition-all duration-300`}
        >
          <a
            href="#home"
            className="nav-link hover:text-green-200 transition-colors duration-300 py-2 md:py-0"
            onClick={()=> setMenuOpen(false)}
          >
            Home
          </a>
          <a
            href="#courses"
            className="nav-link hover:text-green-200 transition-colors duration-300 py-2 md:py-0"
            onClick={()=> setMenuOpen(false)}
          >
            Courses
          </a>
          <a
            href="#learning"
            className="nav-link hover:text-green-200 transition-colors duration-300 py-2 md:py-0"
            onClick={()=> setMenuOpen(false)}
          >
            Learning Material
          </a>
          <a
            href="#values"
            className="nav-link hover:text-green-200 transition-colors duration-300 py-2 md:py-0"
            onClick={()=> setMenuOpen(false)}
          >
            Our Values
          </a>

          <a
            href="#contact"
            className="nav-link hover:text-green-200 transition-colors duration-300 py-2 md:py-0"
            onClick={()=> setMenuOpen(false)}
          >
            Contact
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

// src/components/HeroSection.js
import React from 'react';

const HeroSection = () => {
  return (
    <section id="home" class="hero-gradient text-white pt-32 pb-24">
    <div class="container mx-auto text-center px-4">
        <h2 class="text-5xl font-bold mb-6 leading-tight">Begin Your Journey of <span class="text-green-300">Quranic Learning</span></h2>
        <p class="text-xl mb-12 max-w-2xl mx-auto leading-relaxed opacity-90">Professional one-on-one Quran tutoring from certified teachers, tailored to your learning pace and schedule</p>
        <button 
    onClick={() => window.open('https://forms.gle/UnohcexPMdxnkKgi8', '_blank')}
    class="bg-white text-green-700 px-10 py-4 rounded-full font-bold hover:bg-green-100 transform hover:scale-105 transition-transform duration-300 shadow-lg"
>
    Start Learning Today
</button>

    </div>
</section>
  );
};

export default HeroSection;

// src/components/WhatsAppChatButton.js
import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

const WhatsAppChatButton = () => {
    const phoneNumber = '923295456750'; // Replace with your WhatsApp number including country code

    return (
        <a
            href={`https://wa.me/${phoneNumber}`}
            target="_blank"
            rel="noopener noreferrer"
            className="fixed bottom-10 right-10 bg-green-500 text-white p-4 rounded-full shadow-lg hover:bg-green-600 transition-all duration-300"
            aria-label="Chat on WhatsApp"
        >
            <FaWhatsapp size={32} />
        </a>
    );
};

export default WhatsAppChatButton;

// src/components/ContactSection.js
import React, { useRef } from 'react';
import emailjs from 'emailjs-com';

const ContactSection = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_61d6tsp', 'template_btti6is', form.current, 'iK0eTj5p7aga7d8Ef')
            .then((result) => {
                console.log(result.text);
                form.current.reset();
                alert("Message sent successfully!");
            }, (error) => {
                console.log(error.text);
                alert("Failed to send the message, please try again.");
            });
    };

    return (
        <section id="contact" className="py-24 bg-gray-100">
            <div className="container mx-auto px-4">
                <h2 className="text-4xl font-bold text-center mb-16 gradient-text">Get in Touch</h2>
                <div className="max-w-2xl mx-auto">
                    <div className="bg-white rounded-2xl shadow-xl p-8">
                        <form ref={form} onSubmit={sendEmail} className="space-y-6">
                            <div>
                                <label className="block text-gray-700 mb-2 font-medium">Name</label>
                                <input type="text" name="name" required className="w-full p-4 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-green-500 transition-all duration-300"/>
                            </div>
                            <div>
                                <label className="block text-gray-700 mb-2 font-medium">Email</label>
                                <input type="email" name="email" required className="w-full p-4 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-green-500 transition-all duration-300"/>
                            </div>
                            <div>
                                <label className="block text-gray-700 mb-2 font-medium">Message</label>
                                <textarea name="message" required className="w-full p-4 border border-gray-200 rounded-xl h-32 focus:outline-none focus:ring-2 focus:ring-green-500 transition-all duration-300"></textarea>
                            </div>
                            <button type="submit" className="w-full bg-gradient-to-r from-green-600 to-green-500 text-white py-4 rounded-xl hover:from-green-700 hover:to-green-600 transition-all duration-300 transform hover:scale-105 font-medium">
                                Send Message
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactSection;

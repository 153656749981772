// src/components/HeroSection.js
import React from 'react';

const LearningMaterialSection = () => {
  return (
    <section id="learning" class="py-24 bg-white">
        <div class="container mx-auto px-4">
            <h2 class="text-4xl font-bold text-center mb-16 gradient-text pb-8">Learning Material</h2>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div class="aspect-w-16 aspect-h-9">
                <iframe 
                    src="https://www.youtube.com/embed?v=-Qw2vVcFGMA&list=PLS1t4sVpuxEmtKh80Tn8k5XONgK2vI1De" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen
                    class="w-full h-96 rounded-2xl shadow-xl"
                    title='Rules Playlist'
                ></iframe>
            </div>
            
            <div class="aspect-w-16 aspect-h-9">
                <iframe 
                    src="https://www.youtube.com/embed?v=XQb_KprF9CA&list=PLS1t4sVpuxEn8mgmlBM4nAann5HgMg0wp" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen
                    class="w-full h-96 rounded-2xl shadow-xl"
                    title='Recitations playlist'
                ></iframe>
            </div>
        </div>
        </div>
    </section>
  );
};

export default LearningMaterialSection;
